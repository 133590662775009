<template>
  <div 
    @touchstart.stop
    @touchend.stop
    @touchmove.stop
    class="slider-container"
  >
    <Flicking
      :options="{
        align: { 
          panel: 0, 
          camera: 16,
        },
        adaptive: true,
        interruptable: true,
        circular: true,
        collectStatistics: false,
        overflow: true,
      }"
      :tag="'div'"
      :viewportTag="'div'"
      :cameraTag="'div'"
      :preventClickOnDrag="true"
      :plugins="plugins"
    >
      <SliderChild 
        v-for="bannerItem in items"
        :key="bannerItem.id"
        :title="bannerItem.title"
        :description="bannerItem.description"
        :color="bannerItem.color"
        :fullscreen="bannerItem.full_size"
        :img="bannerItem.image_url"
        @onItemClick="handleBannerClick(bannerItem)"
      />
      <div slot="viewport" class="flicking-pagination"></div>
    </Flicking>
  </div>
</template>
<script>
import SliderChild from "@/components/Main/CardSliderChild";
import { Flicking }  from "@egjs/vue-flicking";
import { mapGetters } from "vuex";
import { postCustomEvent } from "@/helpers/amplitude";
import { Pagination, AutoPlay } from "@egjs/flicking-plugins";
import { handleExternalNavigation } from "@/helpers/utils";

export default {
  name: "MainCardSlider",
  components: {
    Flicking,
    SliderChild
  },
  props: ['items'],
  data(){
    return {
      plugins: [
        new Pagination({ type: 'bullet' }),
        new AutoPlay({ 
          duration: 5000, 
          direction: "NEXT"
        }),
      ],
    }
  },
  computed: {
    ...mapGetters('mainPage', ['get_menu_item_by_form'])
  },
  methods: {
    handleBannerClick(bannerItem){
      postCustomEvent(`Клик на Баннер: ${bannerItem.title}`)
      if (bannerItem?.category === "form_banner"){
        let menuItem = this.get_menu_item_by_form(bannerItem.form_name)
        let to = {}

        if (menuItem?.id) {
          to = {
            name: 'NavigationItemFormPage',
              params: {
                form_name: bannerItem.form_name,
                menu_id: menuItem.id,
              },
              query: bannerItem.params,
            }
        } else if (bannerItem.form_name.toLowerCase().includes('market'.toLowerCase())) {
          to = {
            path: `/s/${bannerItem.form_name}`,
            query: bannerItem.params,
          }
        } else {
          to = {
            name: 'FormPage',
            params: {
              form_name: bannerItem.form_name
            },
            query: bannerItem.params,
          } 
        }
        this.$router.replace(to)
      } else if(bannerItem?.category === "text_banner") {
        this.$emit('openTextBanner', bannerItem)
      } else if (bannerItem?.category === "link_banner"){
        this.$store.dispatch('layout/alert', this.$translate('main-slider_external-nav-alert'));
        handleExternalNavigation(bannerItem.form_name).catch(() => {
          this.$store.dispatch('layout/alert', this.$translate('main-slider_external-nav-error-alert'));
        });
      } else {
        this.$store.dispatch('layout/alert', `Не известная категория баннера: ${bannerItem?.category}`)
      }
    } 
  },
}
</script>
<style lang="scss">
@import "~@egjs/vue-flicking/dist/flicking.css";
@import "~@egjs/flicking-plugins/dist/pagination.css";
.slider-container{
  padding: 6px 0 0 0;
  // margin: 0 -24px 1rem; 
  // min-height: calc((100% - 32px)/3);
  background: #FFF;
  border-radius: 0px 0px 18px 18px;
  position: relative; // so the header and footer overlap
  z-index: 0;
  user-select: none;
  .flicking-viewport {
    padding-bottom: 2rem !important;
    transition: height 500ms;
  }
  .flicking-pagination-bullet{
    background: #EEEDED;
    // opacity: 0.4;
    border-radius: 5px;
    height: 6px;
    width: 6px;
  }
  .flicking-pagination-bullet-active{
    background: #1C1D21;
    opacity: 1;
  }
}
</style>