<template>
  <div class="main-menu">
    <template v-if="items && items.length > 0">
      <template v-for="(menuItem) in items">
        <router-link
          v-if="!menuItem.disabled"
          class="main-menu__item"
          :key="menuItem.id"
          replace
          :to="getMenuItemTo(menuItem)"
        >
          <div class="img-container img-loader">
            <img
              height="36"
              width="36"
              :src="menuItem.icon"
              @load="imgLoadHandler"
              @error="imgErrorHandler"
            >
          </div>
          <p> 
            {{ getTransaltedTitle(menuItem) }}
          </p>
        </router-link>
      </template>
    </template>
    <p v-else>{{ $translate('main-navigation_no-menu-elements') }}</p>
    <template v-if="showDevFeatures">
      <router-link
        v-for="feature in devFeatures"
        :key="feature.title"
        class="main-menu__item"
        :to="feature.to"
      >
        <div
          class="img-container"
        >
          <p class="small">dev</p>
        </div>
        <p>{{ feature.title }}</p>
      </router-link>
      <a
        class="main-menu__item"
        @click.prevent="testLocationAssign"
      >
        <div class="img-container">
          <p class="small">dev</p>
        </div>
        <p>TEST LOCATION ASSIGN</p>
      </a>
      <a
        class="main-menu__item"
        @click.prevent="$store.dispatch('mainPage/setNotOnboarded');"
      >
        <div class="img-container">
          <p class="small">dev</p>
        </div>
        <p>SHOW ONBOArding</p>
      </a>
    </template>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { handleExternalNavigation } from '@/helpers/utils';

export default {
  name: 'MainNavigation',
  props: {
    items: {
      type: Array,
      default(){
        return [];
      },
    }
  },
  computed: {
    ...mapState('layout', ['language']),
    ...mapGetters('mainPage', ['has_children']),
  },
  data(){
    return {
      showDevFeatures: process.env.VUE_APP_SHOW_DEV_FEATURES  === 'true',
      devFeatures: [
      {
          title: 'Календарь событий',
          to: { 
            name: 'ServiceEvents' 
          }
        },
        {
          title: 'Еда',
          to: { 
            name: 'FoodPage' 
          }
        },
        {
          title: 'Стр PDF_1',
          to: {
            name: 'PdfPage',
            query: {
              src: 'https://mozilla.github.io/pdf.js/web/compressed.tracemonkey-pldi-09.pdf',
            },
          }
        },
        {
          title: 'Магазин',
          to: { name: 'Marketplace' }
        },
        {
          title: 'Опрос',
          to: {
            name: 'SurveyByIdPage',
            params: {
              id: 1,
            }
          }
        },
        {
          title: 'ERG CP',
          to: {
            name: 'Divisions'
          }
        },
        {
          title: 'My video',
          to: {
            name: 'MyVideo'
          }
        },
        {
          title: 'Методы bridge',
          to: { name: 'DevPage' }
        },
      ],
    }
  },
  methods: {
    imgLoadHandler: function(event){
      // console.log(event);
      event.target.parentElement.classList.remove('img-loader');
    },
    imgErrorHandler: function(err){
      err.target.parentElement.classList.remove('img-loader');
      err.target.parentElement.innerHTML = '<svg height="32" width="32"><circle cx="16" cy="16" r="16" fill="black" /></svg>'
    },
    testLocationAssign(){
      handleExternalNavigation('https://www.mozilla.org');
    },
    getMenuItemTo(item){
      if (this.has_children(item.id)){
        return {
          name: 'NavigationItemChild',
          params: {
            menu_id: item.id
          }
        }
      } else {
        return `/f/${item.form_name}`
      }
    },
    getTransaltedTitle(item){
      return item?.languages?.[this.language]?.title || item.title
    },
  }
}
</script>
<style lang="scss">
.main-menu{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  background-color: #FFF;
  padding: 16px;
  user-select: none;
  margin-top: 6px;
  border-radius: 18px;
}
a.main-menu__item{
  text-align: center;
  flex: 0 1 25%;
  margin: 12px 0;
  min-width: 0;
  .img-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 44px;
    margin: 0 auto 8px;
    img{
      object-position: center center;
      object-fit: contain;
      width: auto;
      height: 36px;
    }
  }
  .img-loader{
    content:"";
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -36px;
      top: 0;
      height: 100%;
      width: 36px;
      background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
      animation: imgLoad 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }
  }
  p {
    color: #101828;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0;
    text-align: center;
    margin: 0 1px;
    overflow: hidden;
    word-break: break-word;
  }
}

@keyframes imgLoad {
  from {
    left: -32px;
  }
  to   {
    left: 100%;
  }
}
</style>
