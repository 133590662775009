var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slider-container",on:{"touchstart":function($event){$event.stopPropagation();},"touchend":function($event){$event.stopPropagation();},"touchmove":function($event){$event.stopPropagation();}}},[_c('Flicking',{attrs:{"options":{
      align: { 
        panel: 0, 
        camera: 16,
      },
      adaptive: true,
      interruptable: true,
      circular: true,
      collectStatistics: false,
      overflow: true,
    },"tag":'div',"viewportTag":'div',"cameraTag":'div',"preventClickOnDrag":true,"plugins":_vm.plugins}},[_vm._l((_vm.items),function(bannerItem){return _c('SliderChild',{key:bannerItem.id,attrs:{"title":bannerItem.title,"description":bannerItem.description,"color":bannerItem.color,"fullscreen":bannerItem.full_size,"img":bannerItem.image_url},on:{"onItemClick":function($event){return _vm.handleBannerClick(bannerItem)}}})}),_c('div',{staticClass:"flicking-pagination",attrs:{"slot":"viewport"},slot:"viewport"})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }