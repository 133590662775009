<template>
  <div
    class="slider-child"
    :class="{
      'slider-child--fullscreen': fullscreen,
    }"
    :style="{
      backgroundColor: color,
    }"
    @click="$emit('onItemClick')"
  >
    <v-img 
      v-if="fullscreen"
      :src="img" 
      alt=""
    />
    <template v-else>
      <div class="slider-child__left">
        <p>{{ title }}</p>
      </div>
      <div class="slider-child__right">
        <img 
          :src="img" 
          alt="Slider Image"
          @error="imgLoadErrorHandler"
        >
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CardSliderChild',
  props: {
    color: {
      type: String,
      // default: '#62B1EB'
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    textColor: {
      type: String,
      // default: '#00000'
    },
    img: {
      type: String
    },
    title: {
      type: String
    },
    description: {
      type: String
    },
  },
  methods: {
    imgLoadErrorHandler: function(err){
      err.target.parentElement.innerHTML = "";
      // console.error("No image loaded")
    }
  }
}
</script>

<style lang="scss">
.slider-child{
  // min-height: 156px;
  width: calc(100% - 32px);
  height: calc((100vw - 32px)/3);
  margin-right: 28px;
  display: flex;
  background: #62B1EB;
  color: #FFF;
  padding: 12px 8px;
  border-radius: 8px;
  overflow: hidden;
  &.slider-child--fullscreen{
    padding: 0;
  }

  .slider-child__left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 4px;
    flex-grow: 1;
    flex-basis: 0;
    p{
      color: white;
      margin-bottom: 1rem;
      font-weight: 600;
      font-size: calc((80vw - 80px)/13);
      line-height: calc((80vw - 80px)/11);
    }
    a{
      padding: 6px 16px;
      background-color: #FFF;
      color: #000;
      text-decoration: none;
      border-radius: 8px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .slider-child__right{
    text-align: center;
    margin: 0 4px;
    flex-grow: 1;
    flex-basis: 0;
    img{
      object-fit: contain;
      object-position: top center;
      height: 100%;
      width: 100%;
    }
  }
}
</style>