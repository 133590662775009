<template>
  <div class="main-favourites">
    <div 
      v-if="items && items.length > 0"
      class="mb-8 pills-container"
    >
      <h6 class="mb-8">{{ $translate('main-favourites_title') }}</h6>
      <div 
        class="pills"
      >
        <router-link 
          v-for="(pill, key) in items"
          class="pills__item"
          :key="key"
          replace
          :to="
            has_children(pill.menu_item) 
              ? { path: `/m/${pill.menu_item}` } 
              : { path: `/m/${pill.menu_item}/f/${pill.form_name}` }
          "
        >
          {{
            pill.menu_title
          }}
        </router-link>
      </div>
    </div>
    <div 
      v-else
      class="text-center"
    >
      <img 
        src="@/assets/svg/v2_favourites_empty.svg" 
        alt=""
        class="mb-8"
      >
      <h6 
        class="text-gray-900 mb-4"
      >
        {{ $translate('main-favourites_empty-title' )}}
      </h6>
      <p
        class="caption mx-24"
        :style="{
          color: '#929598',
        }"
      >
        {{ $translate('main-favourites_empty-description') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MainFavourites',
  props: {
    items: {
      type: Array,
      default(){
        return [];
      },
    }
  },
  computed: {
    ...mapGetters('mainPage', [
      'has_children',
      'get_menu_item_by_id',
    ])
  }
}
</script>
<style lang="scss">
.main-favourites{
  background: #FFF;
  padding: 16px;
  border-radius: 18px;
  margin: 6px 0 0 0;
  .pills-container{
    user-select: none;
  }
  .pills{
    display: flex;
    flex-wrap: wrap;
  }
  .pills__item{
    display: flex;
    align-items: center;
    margin: 0 0.5rem 0.5rem 0;
    padding: 12px;
    background: #F9F9F9;
    border-radius: 8px;
    white-space: nowrap;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: #0D1616;
    img{
      width: 18px;
      height: 18px;
      margin-right: 8px;
    }
  }
}
</style>