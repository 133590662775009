<template>
  <Fullscreen
    @clear="$emit('close')"
    :has-x-close="true"
  >
    <div class="mx-16">
      <div
        class="slider-card"
        :class="{
          'slider-card--fullscreen': content.full_size,
        }"
        :style="{
          backgroundColor: content.color,
        }"
      >
        <v-img 
          v-if="content.full_size"
          :src="content.image_url" 
          alt=""
        />
        <template v-else>
          <div class="slider-card__left">
            <p>{{ content.title }}</p>
          </div>
          <div class="slider-card__right">
            <img 
              :src="content.image_url" 
              alt="Slider Image"
              @error="imgLoadErrorHandler"
            >
          </div>
        </template>
      </div>
    </div>
    <div 
      class="mx-16"
      v-html="content.description"
    ></div>
  </Fullscreen>
</template>

<script>
import Fullscreen from "@/components/reusable/Fullscreen.vue";
export default {
  name: "MainCardSliderContent",
  components: {
    Fullscreen 
  },
  props: ['content'],
  methods: {
    
    imgLoadErrorHandler: function(err){
      err.target.parentElement.innerHTML = "";
      // console.error("No image loaded")
    },
    
  }
}
</script>
<style lang="scss">
.slider-card{
  width: 100%;
  height: auto;//calc((100vw - 48px)/2);
  display: flex;
  background: #62B1EB;
  color: #FFF;
  padding: 24px 8px;
  border-radius: 12px;
  margin: 0 0 24px 0;
  overflow: hidden;

  &.slider-card--fullscreen{
    padding: 0;
  }
  .slider-card__left{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 4px;
    flex-grow: 1;
    flex-basis: 0;
    p{
      color: white;
      margin-bottom: 1rem;
      font-weight: 600;
      font-size: calc((100vw - 48px)/13);
      line-height: calc((100vw - 48px)/11);
    }
    a{
      padding: 6px 16px;
      background-color: #FFF;
      color: #000;
      text-decoration: none;
      border-radius: 8px;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .slider-card__right{
    text-align: center;
    margin: 0 4px;
    flex-grow: 1;
    flex-basis: 0;
    img{
      object-fit: contain;
      object-position: top center;
      height: 100%;
      width: 100%;
    }
  }
}
</style>