<template>
  <header class="layout__header">
    <div class="main-header">
      <SearchInput
        v-model="search"
        @onClick="onSearchClick"
      />
    </div>
  </header>
</template>

<script>
import { postCustomEvent } from "@/helpers/amplitude";
import SearchInput from "@/components/partials/SearchInput";
import headerMixin from "@/mixins/header.js";

export default {
  name: "MainHeaderView",
  components: {
    SearchInput
  },
  data(){
    return {
      searchValue: '',
      bgColor: 'default'
    }
  },
  mixins: [ headerMixin ],
  computed: {
    search: {
      get(){
        return this.searchValue;
      },
      set(value){
        this.$router.replace({
          name: "MainPage", 
          query: { 
            search: value,
          },
        })
      },
    },
  },

  // TODO - проанализировать, возможно нужно убрать т.к. есть watch в main
  watch: {
    '$route.query.search': function(newValue){
      this.searchValue = newValue;
    },
  },
  methods: {
    onSearchClick(){
      postCustomEvent('Нажатие на Поиск');
    }
  }
}
</script>
<style lang="scss">
.main-header__actions{
  display: flex;
  justify-content: space-between;
  align-items: center;
  .main-header__actions__menu{
    margin: 6px
  }
}
</style>
