<template>
  <main 
    class="layout__body layout__body--main"
    :class="{
      'layout__body--error-placeholder': filter && !menu_filtered_by(filter).length
    }"
  >
    <!-- <let-it-snow
      :windPower="1"  
      :speed="2"
      :count="20"
      :size="3"
      :opacity="0.8"
      :image="false"
      :show="isSnowing"    
    ></let-it-snow> --> 
    <template v-if="filter">
      <SubMenu 
        v-if="menu_filtered_by(filter).length"
        :items="menu_filtered_by(filter)"
      />
      <div 
        v-else
        class="filter__not-found"
      >
        <svg width="47" height="48" viewBox="0 0 47 48" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'filter__not-found__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><ellipse cx="20.231" cy="20.858" rx="20.065" ry="20.191" fill="#8897A5"/><path opacity=".4" d="M43.74 47.229a3.082 3.082 0 01-2.074-.898l-4.752-5.554a2.537 2.537 0 01-.187-3.55 2.275 2.275 0 013.237 0l5.976 4.782a3.146 3.146 0 01.678 3.34 3.114 3.114 0 01-2.753 1.984l-.124-.104z" fill="#8897A5"/></svg>
        <h6 class="filter__not-found__title">
          {{ $translate('main_search_no-result-title')}}
        </h6>
        <p class="filter__not-found__text">
          {{ $translate('main_search_no-result-description') }}
        </p>
      </div>
    </template>
    <template v-else>
      <MainCardSlider
        :items="slider.items"
        :key="show_burger_content"
        @openTextBanner="setSliderContent"
      />
      <MainNavigation 
        :items="menu_parents"
      />
      <MainCardSliderContent 
        v-if="sliderContent"
        :content="sliderContent"
        @close="sliderContent = undefined"
      />
      <MainFavourites
        :items="favourite.items"
      />
    </template>
  </main>
</template>
<script>
import MainCardSlider from "@/components/Main/MainCardSlider";
import MainFavourites from "@/components/Main/MainFavourites";
import MainNavigation from "@/components/Main/MainNavigation";
import MainCardSliderContent from "@/components/Main/MainCardSliderContent.vue";
import SubMenu from "@/components/Main/SubMenu";
import { mapState, mapGetters } from "vuex";
import { backNavigateQueue } from "@/helpers/app.js";

export default {
  name: 'MainView',
  components: {
    MainCardSlider,
    MainCardSliderContent,
    MainFavourites,
    MainNavigation,
    SubMenu,
  },
  data(){
    return {
      filter: undefined,
      sliderContent: undefined,
      isSnowing: false,
    }
  },
  computed: {
    ...mapState('mainPage', ['slider', 'favourite']),
    ...mapState('layout', ['show_burger_content']),
    ...mapGetters('mainPage', ['menu_parents', 'menu_filtered_by']),
  },
  watch: {
    '$route.query.search': function(newValue){
      this.filter = newValue;
    },
  },
  methods: {
    setSliderContent(content){
      this.sliderContent = content;
    }
  },
  mounted(){
    this.$store.dispatch('layout/setHeaderTitle', process.env.VUE_APP_MENU_TITLE);
    backNavigateQueue.clear();
    this.$nextTick(() => {
      this.isSnowing = true;
    });
  },
}
</script>
<style lang="scss">
.filter__not-found{
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  text-align: center;
  .filter__not-found__title{
    margin-top: 1rem;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 28px;
    color: #151C22;
  }
  .filter__not-found__text{
    margin: 0.5rem auto 0;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #667A8B;
    max-width: 280px;
  }
}
</style>